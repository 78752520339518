
































































































import { Component, Mixins } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import CampaignCard from '@/ui/components/molecules/campaigns/CampaignCard.vue';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import CampaignStatisticsFilter from '@/ui/components/organisms/filter/CampaignStatisticsFilter.vue';
import StatisticCard from '@/ui/components/molecules/statistics/StatisticCard.vue';
import SummaryCard from '@/ui/components/molecules/statistics/SummaryCard.vue';
import StatisticCharts from '@/ui/components/molecules/statistics/StatisticCharts.vue';
import { playoutFormat } from '@/ui/util/numberFormat';
import { ROUTE_CAMPAIGN_DETAIL } from '@/ui/router/campaign.routes';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { GeneralStatistic, ValueByZone, ValuesPerMonthAndZone } from '@/core/statistics/models/Statistics';
import {
  getEndDateStatisticsFilter,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getStartDateStatisticsFilter,
} from '@/ui/util/date';
import { StatisticStoreActions, StatisticStoreMutations } from '@/ui/stores/StatisticStore';
import { STATISTIC } from '@/ui/constant/statistic.const';
import i18n from '@/i18n';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import type { Tag } from '@/core/tags/models/Tag';
import { TagType } from '@/core/tags/enums/TagType';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import { CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { UserStoreMutations } from '@/ui/stores/UserStore';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('AllCampaignStatistics.vue');

@Component({
  name: 'AllCampaignStatistics',
  components: {
    CampaignStatisticsFilter,
    CampaignCard,
    StatisticCard,
    StatisticCharts,
    SummaryCard,
  },
})
export default class AllCampaignStatistics extends Mixins(NavigationMixin) {
  private startDate: string = this.selectedStartDate || getStartDateStatisticsFilter();
  private endDate: string = this.selectedEndDate || getEndDateStatisticsFilter();
  private statisticView = STATISTIC.GENERAL;
  private zones: Tag[] = [];

  protected CampaignDetail = ROUTE_CAMPAIGN_DETAIL;
  locale: keyof LanguageObject = i18n.i18next.language;

  async created() {
    this.fetchTags('');
    if (this.doesNotHavePreviousStatisticsSaved() || this.customerHasChanged()) {
      await this.fetchGeneralStatistic(this.startDate, this.endDate);
      this.$store.commit(UserStoreMutations.UPDATE_PREVIOUS_CUSTOMER_ID, this.currentCustomerId);
    }
  }

  get selectedDates() {
    return this.startDate && this.endDate ? [this.startDate, this.endDate] : [];
  }

  get selectedStartDate() {
    return this.$store.state.statisticStore.startDate;
  }

  get selectedEndDate() {
    return this.$store.state.statisticStore.endDate;
  }

  get selectedStatisticsZones() {
    return this.$store.state.campaignStore.selectedStatisticsZones;
  }

  get generalStatistic(): GeneralStatistic | undefined {
    return this.$store.state.statisticStore.generalStatistic;
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get previousCustomerId() {
    return this.$store.state.userStore.previousCustomerId;
  }

  get currentCustomerId() {
    return this.$store.state.customerStore.customer?.id || this.currentUser.customerId;
  }

  get campaignCount() {
    return playoutFormat(this.generalStatistic?.campaignCount);
  }

  get mediaCount() {
    return playoutFormat(this.generalStatistic?.mediaCount);
  }

  get mediaValue() {
    return playoutFormat(this.generalStatistic?.mediaValue);
  }

  get mediaValueByZone(): ValueByZone[] | undefined {
    return this.reduceAndSort(this.generalStatistic?.mediaValuePerMonthByZone);
  }

  get playouts() {
    return playoutFormat(this.generalStatistic?.playouts);
  }

  get playoutsByZone(): ValueByZone[] | undefined {
    return this.reduceAndSort(this.generalStatistic?.playoutsPerMonthByZone);
  }

  get playoutsPerMonthByZone() {
    return this.generalStatistic?.playoutsPerMonthByZone;
  }

  get topCampaigns() {
    return this.generalStatistic?.topCampaigns;
  }

  get zoneCount() {
    return playoutFormat(this.generalStatistic?.zoneCount);
  }

  get hasPlayouts() {
    return this.playoutsPerMonthByZone && this.playoutsPerMonthByZone?.length > 0;
  }

  async fetchGeneralStatistic(startDate: string, endDate: string, filteredZones?: string[]) {
    this.$store.commit(StatisticStoreMutations.SET_START_DATE, startDate);
    this.$store.commit(StatisticStoreMutations.SET_END_DATE, endDate);
    await this.$store.dispatch(StatisticStoreActions.SET_GENERAL_STATISTIC, {
      user: this.currentUser,
      customerId: this.currentCustomerId,
      startDate: getFirstDayOfMonth(startDate),
      endDate: getLastDayOfMonth(endDate),
      zoneIds: filteredZones && filteredZones.length > 0 ? filteredZones : undefined,
    });
  }

  async onUpdateFilter(filteredZones: string[], filteredDates: string[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_STATISTICS_ZONES, filteredZones);
    this.fetchGeneralStatistic(filteredDates[0], filteredDates[1], filteredZones);
  }

  async fetchTags(search: string) {
    this.zones = await findTagsUseCase({ search, type: TagType.ZONE }, this.currentUser);
  }

  doesNotHavePreviousStatisticsSaved() {
    return this.$store.state.statisticStore.generalStatistic === undefined;
  }

  customerHasChanged() {
    return this.currentCustomerId !== this.previousCustomerId;
  }

  checkContent() {
    if (!this.hasPlayouts) {
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: 'campaign.campaign-statistics.no-data-available',
        type: AlertType.WARNING,
      });
    }
  }

  reduceAndSort(array: ValuesPerMonthAndZone[] | undefined) {
    const res = array?.map((item) => {
      return {
        zone: item.zone,
        value: item.value.reduce((a, b) => a + b.value, 0),
      };
    });
    return this.sort(res);
  }

  sort(array: ValueByZone[] | undefined) {
    return array?.sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0));
  }
}
