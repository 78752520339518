








import { Component, Vue } from 'vue-property-decorator';
import AllCampaignStatistics from '@/ui/components/organisms/campaigns/AllCampaignStatistics.vue';
export interface SuccessMessagePayload {
  message: string;
}

@Component({
  name: 'StatisticsView',
  components: {
    AllCampaignStatistics,
  },
})
export default class StatisticsView extends Vue {
  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }
}
