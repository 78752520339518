























import { ValueByZone } from '@/core/statistics/models/Statistics';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'StatisticCard',
})
export default class StatisticCard extends Vue {
  @Prop({ type: Array, default: () => [] }) statisticSummary?: ValueByZone[];
  @Prop({ default: '' }) title?: string;
  @Prop({ default: 0 }) total?: number | string;
  private zoneColors = ['#e0e4f2', '#bdc7d7', '#a6b1c3', '#8390a5', '#5d6777'] as string[];

  formatPlayOut = (playOut: number): string | undefined =>
    playOut ? (Math.round((playOut + Number.EPSILON) * 100) / 100).toLocaleString('de-CH') : undefined;
}
